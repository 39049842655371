import React from 'react'
import BaseComponent from './BaseComponent'
import { NavLink } from 'react-router-dom'
import _ from 'lodash'
import Logo from "../../img/bat-logo-white.svg"

export default class SideNav extends BaseComponent {

  constructor(props) {
  	super(props)
  	this.state = { anim: "", popup: false, expand: [] }
  }

  componentDidMount() {
  	setTimeout(() => { this.setState({ anim: "anim" }) }, 400)
  }

  render() {
  	let nav
  	this.props.nav ? nav = "side-nav open" : nav = "side-nav"

    let navItems = this.props.data.nav.map((item, i) => {
      if (item.role !== undefined) {
        if (this.props.user.role === item.role && !item.hasParent) {
          if (item.hasChildren) {
            let items = _.filter(this.props.data.nav, { hasParent: item.name }) || []
            return (
              <li key={ i }>
                <span className={ this.state.expand[i] ? "expand open" : "expand" } onClick={ () => { if (this.state.expand[i]) { let expand = []; expand[i] = false; this.setState({ expand: expand }) } else { let expand = []; expand[i] = true; this.setState({ expand: expand }) } } }><div className="img"><img src={ item.icon } alt=""/></div>{ item.name }</span>
                { item.hasChildren && items.length > 0 && this.state.expand[i] ? <ul className="nav-items">
                  { items.map((navItem, j) => {
                    return (
                      <li key={ i + "-" + j }><NavLink to={ navItem.route } exact={ navItem.route === "/" ? "true" : "false" } onClick={ this.props.hideNav }><div className="img"><img src={ navItem.icon } alt=""/></div>{ navItem.name }</NavLink></li>
                    )
                  }) }
                </ul> : null }
              </li>
            )
          } else {
            if(item.domain) {
              if(item.domain === this.props.selectedDomain.slug) {
                return (
                  <li key={ i }><NavLink to={ item.route } exact={ item.route === "/" ? "true" : "false" } onClick={ this.props.hideNav }><div className="img"><img src={ item.icon } alt=""/></div>{ item.name }</NavLink></li>
                )
              } else {
                return false
              }
            } else {
              return (
                <li key={ i }><NavLink to={ item.route } exact={ item.route === "/" ? "true" : "false" } onClick={ this.props.hideNav }><div className="img"><img src={ item.icon } alt=""/></div>{ item.name }</NavLink></li>
              )
            }
          }
        } else {
          return false
        }
      } else {
        if (item.hasChildren) {
          let items = _.filter(this.props.data.nav, { hasParent: item.name }) || []
          return (
            <li key={ i }>
              <span className={ this.state.expand[i] ? "expand open" : "expand" } onClick={ this.props.hideNav }><div className="img"><img src={ item.icon } alt=""/></div>{ item.name }</span>
              { item.hasChildren && items.length > 0 && this.state.expand[i] ? <ul className="nav-items">
                { items.map((navItem, j) => {
                  return (
                    <li key={ i + "-" + j }><NavLink to={ navItem.route } exact={ navItem.route === "/" ? "true" : "false" } onClick={ this.props.hideNav }><div className="img"><img src={ navItem.icon } alt=""/></div>{ navItem.name }</NavLink></li>
                  )
                }) }
              </ul> : null }
            </li>
          )
        } else {
          if(item.domain) {
            if(item.domain === this.props.selectedDomain.slug) {
              return (
                <li key={ i }><NavLink to={ item.route } exact={ item.route === "/" ? "true" : "false" } onClick={ this.props.hideNav }><div className="img"><img src={ item.icon } alt=""/></div>{ item.name }</NavLink></li>
              )
            } else {
              return false
            }
          } else {
            return (
              <li key={ i }><NavLink to={ item.route } exact={ item.route === "/" ? "true" : "false" } onClick={ this.props.hideNav }><div className="img"><img src={ item.icon } alt=""/></div>{ item.name }</NavLink></li>
            )
          }
        }
      }
    })

    return (
      <div className={ nav + " " + this.state.anim }>
	     	<div className="close" onClick={ this.props.hideNav }></div>
	    	<div className="top">
	    		<img className="side-logo" src={ Logo } alt="BAT logo"/>
	    	</div>
        <nav>
          <ul className="nav-items">
            { navItems }
          </ul>
        </nav>
	    	<div className="tdy-logo"><a href="https://tdy.pl" target="_blank" rel="noopener noreferrer"><img src="/assets/img/logo-white-today.png" alt="Today. Logo"/></a></div>
      </div>
    )
  }
}
