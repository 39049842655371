import React from 'react'
import BaseComponent from './BaseComponent'
import { NavLink } from 'react-router-dom'

export default class Header extends BaseComponent {

	constructor(props) {
		super(props)
		this.state = { sticky: "", anim: "", popup: false }
	}

  componentDidMount() {
  	document.addEventListener('scroll', (e) => {
      if (document.body.scrollTop > 10 || document.documentElement.scrollTop > 10) {
          this.setState({ sticky: "sticky" })
      } else {
          this.setState({ sticky: "" })
      }
    })

    setTimeout(() => { this.setState({ anim: "anim" }) }, 400)
  }

  render() {
  	let nav
  	this.props.nav ? nav = "nav-open" : nav = "nav-closed"

    return (
      <header className={ this.state.sticky + " " + this.state.anim + " " + nav }>
        <div className="header-inner">
        	<div className="burger" onClick={ this.props.toggleNav }><div className="line"></div></div>
        	<div className="user">
            <p className='page-name black'>inspirationclub.online</p>
            <NavLink to={ "/user" }>
          		<span className="name">{ this.props.user ? this.props.user.name : null }</span>
            </NavLink>
            <NavLink to={ "/user" }>
          		<div className="avatar" style={{ backgroundImage: "url(/assets/img/avatar-desktop.svg)" }}></div>
            </NavLink>
        		<button className="btn btn-logout" onClick={ this.props.logout }><span>Sign out</span></button>
        	</div>
        </div>
      </header>
    )
  }
}
