/* Component for easier ES6 binding */

import { Component } from 'react';

export const API = process.env.REACT_APP_API_URL

export default class BaseComponent extends Component {

	// The rest parameter syntax allows to represent an indefinite number of arguments as an array.
	// https://developer.mozilla.org/pl/docs/Web/JavaScript/Reference/Functions/rest_parameters
	_bind(...methods) {
		methods.forEach( (method) => this[method] = this[method].bind(this) );
	}

	setContent(id) {
		this.setState({ index : id })
	}

	setWrapperHeight(h) {
		this.props.setHeight(h)
	}
}

export function leadingZero(day) {
	let d = parseInt(day);
	if (d < 10) {
	  return "0" + d;
	} else {
	  return "" + d;
	}
  }
  
  export function parseDate(d, hours, showSeconds) {
	let date = new Date(d);
	let seconds = ":" + leadingZero(date.getSeconds());
  
	if (showSeconds === false) {
	  seconds = "";
	}
  
	if (hours) {
	  return (
		leadingZero(date.getDate()) +
		"." +
		leadingZero(date.getMonth() + 1) +
		"." +
		date.getFullYear() +
		", " +
		leadingZero(date.getHours()) +
		":" +
		leadingZero(date.getMinutes()) +
		seconds
	  );
	} else {
	  return (
		leadingZero(date.getDate()) +
		"." +
		leadingZero(date.getMonth() + 1) +
		"." +
		date.getFullYear()
	  );
	}
  }

export function errorInputValidator(error) {
	if(error?.includes("Validator")) {
		const type = error.split("/")[error.split("/").length-1].split("_")[0]
		const index = error.split("/")[error.split("/").length-1].split("_")[1]

		if(index) {
			setTimeout(() => { document.querySelector("[data-moduleindex='" + index + "'][data-moduletype='" + type + "']")?.scrollIntoView({behavior: "smooth", block: "center", inline: "center"})
				setTimeout(() => {
					document.querySelector("[data-moduleindex='" + index + "'][data-moduletype='" + type + "']")?.classList.add("input-error")
					if(document.querySelector("[data-moduleindex='" + index + "'][data-moduletype='" + type + "'] .ck-content")) {
						document.querySelector("[data-moduleindex='" + index + "'][data-moduletype='" + type + "'] .ck-content").focus()
					}
					setTimeout(() => {
						document.querySelector("[data-moduleindex='" + index + "'][data-moduletype='" + type + "']")?.classList.remove("input-error")
					}, 3000)
				}, 300)
			}, 300)
		} else {
			setTimeout(() => { document.querySelector("[data-moduletype='" + type + "']")?.scrollIntoView({behavior: "smooth", block: "center", inline: "center"})
				setTimeout(() => {
					document.querySelector("[data-moduletype='" + type + "']")?.classList.add("input-error")
					if(document.querySelector("[data-moduletype='" + type + "'] .ck-content")) {
						document.querySelector("[data-moduletype='" + type + "'] .ck-content").focus()
					}
					setTimeout(() => {
						document.querySelector("[data-moduletype='" + type + "']")?.classList.remove("input-error")
					}, 3000)
				}, 300)
			}, 300)
		}
	}
}
  
export function errorMap(error, details) {
	if(details?.error_bad_value_of_required) {
		let detailsMap = details?.error_bad_value_of_required
		let errorDesc = error + "<br/><br/>details:"
		detailsMap.forEach(element => {
			errorDesc += "<br/>" +element
		});
		return errorDesc
	}
	return error
}