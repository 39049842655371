import React from 'react'
import BaseComponent, { errorMap } from './BaseComponent'
import Dropzone from 'react-dropzone'
import { srcToWebP } from 'webp-converter-browser'

export default class SingleImageUpload extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { files: [], imageError: false, preloader: false, preview: "" }
    this._bind('onDrop', 'removeFiles', 'remove', 'save')
    // this.getFileData(this.props.image)
  }

  onDrop(acceptedFiles, rejectedFiles) {
    if(acceptedFiles.length > 0) {
        this.setState({
            imageError: false,
            files: acceptedFiles.map(file => Object.assign(file, {
              preview: URL.createObjectURL(file)
            }))
        })
        this.save(acceptedFiles)
    } else if(rejectedFiles.length > 0) {
        console.log("rejectedFiles")
        this.setState({
            imageError: true
        })
    }
  }

  removeFiles() {
    this.setState({
      files: []
    })
    if(this.props.image && this.props.image.uuid) this.remove(this.props.image.uuid)
  }

  toDataURL = url => fetch(url)
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(blob)
  }))

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
  }

  reuploadFiles() {
    if(this.props.image && this.props.image.uuid) {
      const fileSplit = this.props.image.url.split("/")
      const fileName = fileSplit[fileSplit.length-1]
      const fileType = fileName.split(".")[1]
      const shortFileName = fileName.split("-")[0]
      this.toDataURL(this.props.image.url)
      .then(dataUrl => {
        var fileData = this.dataURLtoFile(dataUrl, `${shortFileName}.${fileType}`);
        let acceptedFiles = []
        acceptedFiles.push(fileData)
        this.setState({
          imageError: false,
          files: acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
          }))
        })
        this.save(acceptedFiles)
      })
    }
  }

  remove(uuid) {
    this.setState({ preloader: true })
    let url = this.props.API+"/edit/trash/recursive/file/"+uuid
    fetch(url, {
      method: "POST",
      headers: {
          "Authorization": "Bearer " + sessionStorage.getItem('token')
      }
    })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      if (!result.status.success) {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : errorMap(result.data?.error?.ident || result.data?.error)}}), true);
        this.setState({ preloader: false })
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Usunięto plik. Pamiętaj aby zapisać zmiany!"}}), true);
        this.setState({ preloader: false })
        this.props.onChange(null)
      }
    }, (error) => {
      //console.log(error)
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Something went wrong."}}), true);
      this.setState({ preloader: false })
    })
  }

  save(files) {
    this.setState({ preloader: true })
    let formData = new FormData()
    for (var f = 0; f < files.length; f++) {
        formData.append('files[0]', files[f], files[f].name);
    }

    let params = {
      file: {
        restricted: false
      }
    }

    formData.append('_input', JSON.stringify(params));
    let data = formData;

    let url = this.props.API+"/file/upload"
    fetch(url, {
      method: "POST",
      headers: {
          "Authorization": "Bearer " + sessionStorage.getItem('token')
      },
      body: data
    })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      if (!result.status.success) {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : errorMap(result.data?.error?.ident || result.data?.error)}}), true);
        this.setState({ preloader: false })
      } else {
        // window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Dodano plik. Pamiętaj aby zapisać zmiany!"}}), true);
        this.setState({ preloader: false })
        // this.getFileData(result.data.files[0].uuid)
        this.props.onChange(result.data[0])
      }
    }, (error) => {
      //console.log(error)
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Something went wrong."}}), true);
      this.setState({ preloader: false })
    })
  }

  optimizeAsset(image) {
    if(this.props.image && this.props.image.uuid) {
      this.setState({ preloader: true })

      const fileSplit = this.props.image.url.split("/")
      const fileName = fileSplit[fileSplit.length-1]
      const shortFileName = fileName.split("-")[0]

      srcToWebP(image, {
        quality: 75,
        width: 720,
        height: 1356
      }).then(blob => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
      })).then(dataUrl => {
        var fileData = this.dataURLtoFile(dataUrl, `${shortFileName}.webp`);
        let acceptedFiles = []
        acceptedFiles.push(fileData)
        this.save(acceptedFiles)
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Asset optimised! Remember to save changes."}}), true);
      })
    }
  }
  
  // getFileData(uuid) {
  //   if(uuid && (this.props.ident === "image" || this.props.ident === "thumbnail" || this.props.ident === "icon")) {
  //     let url = this.props.API+"/file/" + uuid

  //     fetch(url, {
  //       method: "GET",
  //       headers: {
  //           "Authorization": "Bearer " + sessionStorage.getItem('token')
  //       }
  //     })
  //     .then((response) => {
  //       return response.blob()
  //     }).then(imageBlob => {
  //       const imageObjectURL = URL.createObjectURL(imageBlob);
  //       this.setState({ preview: imageObjectURL })
  //     });
  //   }
  // }

  render () {
    let image = this.state.files.length > 0 ? this.state.files[0].preview : this.props.image?.url
    // console.log(this.props.image)

    return (
      <div className="dropzone-holder">
        <Dropzone maxSize={10000000} accept={["image/png","image/jpeg","image/svg+xml", "image/svg"]} className={ this.state.imageError ? "dropzone dropzone-reject" : "dropzone" } activeClassName="dropzone-active" rejectClassName="dropzone-reject" multiple={ false } onDrop={ this.onDrop }>
        {({getRootProps, getInputProps}) => (
            <div className={ this.state.imageError ? "dropzone dropzone-reject" : "dropzone" }>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                { image ? <img src={ image } alt=""/> : <p>Drag'n'drop do add file</p> }
              </div>
            </div>
          )}
        </Dropzone>
        { this.props.image && this.props.image.uuid && !this.props.hideDel ? <div className="remove-file" onClick={ () => { this.removeFiles() } }>Remove</div> : null }
        { this.props.image && this.props.image.uuid ? <div className="reupload-file" onClick={ () => { this.reuploadFiles() } }>Reupload</div> : null }
        { window.location.href.includes("/story/") && this.props.image && this.props.image.uuid ? <div className="optimize-file" onClick={ () => this.optimizeAsset(image) }>Optimize</div> : null }
        { this.state.preloader ? <div className="preloader"><span></span></div> : null }
      </div>
    )
  }
}
