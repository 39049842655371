import React from 'react'
import BaseComponent from './BaseComponent'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/ckbuild/ckeditor';
import SingleImageUpload from './SingleImageUpload'
// import SingleFileUpload from './SingleFileUpload'
// import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import { registerLocale } from "react-datepicker";
import pl from 'date-fns/locale/pl';
import AddTerm from './AddTerm'

registerLocale('pl', pl)

export default class Modules extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { files: [] }
    this._bind('updateItem')
  }

  updateItem(index, type, value) {
    let items = [...this.props.items]
    items[index][type] = value
    this.props.changeModuleItems(items)
  }

  render () {
    //console.log(this.state)
    return (
      <div className="modules single-use">
        <ul>
            { this.props.availableModules.map((item, i) => {
              // console.log(this.props.items)
                let value = this.props?.items && this.props?.items.length > 0 ? this.props?.items[i] : {}
                let component = null
                if (item === "text" || item === "intro" || item === "disclaimer") {
                  component = <div>
                      <h4>Intro<span>*</span></h4>
                      <CKEditor
                        editor={ ClassicEditor }
                        config={ {
                          toolbar: [ 'heading', '|', 'bold', 'italic', 'underline', 'fontSize', '|', 'subscript', 'superscript', 'alignment', 'link', 'blockQuote', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'undo', 'redo' ],
                          heading: {
                          options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'H1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h2', title: 'H2', class: 'ck-heading_heading2' },
                            { model: 'heading3', view: 'h3', title: 'H3', class: 'ck-heading_heading3' }
                          ]
                        },
                          
                        link: {
                          addTargetToExternalLinks: true,
                          decorators: {
                            addTargetToExternalLinks: {
                              mode: 'automatic',
                              callback: url => /^(https?:)?\/\//.test( url ),
                              attributes: {
                                  target: '_blank',
                                  rel: 'noopener noreferrer'
                                }
                            }
                          }
                        }
                        } }
                        data={ value.custom || "" }
                        onReady={ editor => {
                            // You can store the "editor" and use when it is needed.
                            // console.log( 'Editor is ready to use!', editor );
                        } }
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            //console.log( { event, editor, data } );
                            this.updateItem(i, "custom", data)
                        } }
                        onBlur={ ( event, editor ) => {
                            //console.log( 'Blur.', editor );
                        } }
                        onFocus={ ( event, editor ) => {
                            //console.log( 'Focus.', editor );
                        } }
                    /></div>
                } else if(item === "columns") {
                  let val = value
                  if (!val.columns) {
                    val.columns = [{custom: ""}, {custom: ""}]
                  }
    
                  component = (
                    <div>
                      <h4>Columns</h4>
                      <div className="flex-columns">
                        <CKEditor
                          editor={ ClassicEditor }
                          config={ {
                            toolbar: [ 'heading', '|', 'bold', 'italic', 'underline', 'fontSize', '|', 'subscript', 'superscript', 'alignment', 'link', 'blockQuote', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'undo', 'redo' ],
                            heading: {
                          options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'H1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h2', title: 'H2', class: 'ck-heading_heading2' },
                            { model: 'heading3', view: 'h3', title: 'H3', class: 'ck-heading_heading3' }
                          ]
                        },
                        link: {
                          addTargetToExternalLinks: true,
                          decorators: {
                            addTargetToExternalLinks: {
                              mode: 'automatic',
                              callback: url => /^(https?:)?\/\//.test( url ),
                              attributes: {
                                  target: '_blank',
                                  rel: 'noopener noreferrer'
                                }
                            }
                          }
                        }
                          } }
                          data={ val.columns[0].custom }
                          onReady={ editor => {
                              // You can store the "editor" and use when it is needed.
                              // console.log( 'Editor is ready to use!', editor );
                          } }
                          onChange={ ( event, editor ) => {
                              const data = editor.getData();
                              // val.columns[0].custom = data
                              console.log( { event, editor, data } );
                              // this.updateItem(i, "columns", val.columns)
                          } }
                          onBlur={ ( event, editor ) => {
                              //console.log( 'Blur.', editor );
                          } }
                          onFocus={ ( event, editor ) => {
                              //console.log( 'Focus.', editor );
                          } }
                        />
                        <CKEditor
                          editor={ ClassicEditor }
                          config={ {
                            toolbar: [ 'heading', '|', 'bold', 'italic', 'underline', 'fontSize', '|', 'subscript', 'superscript', 'alignment', 'link', 'blockQuote', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'undo', 'redo' ],
                            heading: {
                          options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'H1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h2', title: 'H2', class: 'ck-heading_heading2' },
                            { model: 'heading3', view: 'h3', title: 'H3', class: 'ck-heading_heading3' }
                          ]
                        },
                        link: {
                          addTargetToExternalLinks: true,
                          decorators: {
                            addTargetToExternalLinks: {
                              mode: 'automatic',
                              callback: url => /^(https?:)?\/\//.test( url ),
                              attributes: {
                                  target: '_blank',
                                  rel: 'noopener noreferrer'
                                }
                            }
                          }
                        }
                          } }
                          data={ val.columns[1].custom }
                          onReady={ editor => {
                              // You can store the "editor" and use when it is needed.
                              // console.log( 'Editor is ready to use!', editor );
                          } }
                          onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            // val.columns[1].custom = data
                            console.log( { event, editor, data } );
                            // this.updateItem(i, "columns", val.columns)
                          } }
                          onBlur={ ( event, editor ) => {
                              //console.log( 'Blur.', editor );
                          } }
                          onFocus={ ( event, editor ) => {
                              //console.log( 'Focus.', editor );
                          } }
                        />
                      </div>
                    </div>
                  )
                } else if (item === "image-text") {
                  let val = value
    
                  if (!val.file) {
                    val.file = {uuid: ""}
                  }
    
                  if(!val.metadata) {
                    val.metadata = {
                      layout: "left",
                      alt: "",
                      imgDesc: ""
                    }
                  }
    
                  component = <div>
                      <h4>Content (HTML)</h4>
                      <CKEditor
                          editor={ ClassicEditor }
                          config={ {
                            toolbar: [ 'heading', '|', 'bold', 'italic', 'underline', 'fontSize', '|', 'subscript', 'superscript', 'alignment', 'link', 'blockQuote', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'undo', 'redo' ],
                            heading: {
                          options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'H1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h2', title: 'H2', class: 'ck-heading_heading2' },
                            { model: 'heading3', view: 'h3', title: 'H3', class: 'ck-heading_heading3' }
                          ]
                        },
                        link: {
                          addTargetToExternalLinks: true,
                          decorators: {
                            addTargetToExternalLinks: {
                              mode: 'automatic',
                              callback: url => /^(https?:)?\/\//.test( url ),
                              attributes: {
                                  target: '_blank',
                                  rel: 'noopener noreferrer'
                                }
                            }
                          }
                        }
                          } }
                          data={ val.custom }
                          onReady={ editor => {
                              // You can store the "editor" and use when it is needed.
                              // console.log( 'Editor is ready to use!', editor );
                          } }
                          onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            //console.log( { event, editor, data } );
                            this.updateItem(i, "custom", data)
                          } }
                          onBlur={ ( event, editor ) => {
                              //console.log( 'Blur.', editor );
                          } }
                          onFocus={ ( event, editor ) => {
                              //console.log( 'Focus.', editor );
                          } }
                      />
                      <h4>Image</h4>
                      <SingleImageUpload ident={ "image" } API={ this.props.API } image={ val.file.uuid } onChange={ (img) => {
                        val.file.uuid = img
                        this.updateItem(i, "file", val.file)
                        } }/>
                      <p className="input-desc">Alternative text</p>
                      <input type="text" value={ val.metadata.alt } onChange={ (e) => { val.metadata.alt = e.target.value; this.updateItem(i, "metadata", val.metadata) } }/>
                      <p className="input-desc">Image description</p>
                      <input type="text" value={ val.metadata.imgDesc } onChange={ (e) => { val.metadata.imgDesc = e.target.value; this.updateItem(i, "metadata", val.metadata) } }/>
                      <h4>Layout</h4>
                      <select onChange={ (e) => { val.metadata.layout = e.target.value; this.updateItem(i, "metadata", val.metadata) } } value={ val.metadata.layout }>
                          <option value="left">Text on left</option>
                          <option value="right">Text on right</option>
                      </select>
                    </div>
                } else if (item === "address") {
                  let val = value
    
                  if(!val.metadata) {
                    val.metadata = {
                      name: "",
                      street: "",
                      buildingNumber: "",
                      apartmentNumber: "",
                      city: "",
                      postalCode: "",
                      latitude: "",
                      longitude: ""
                    }
                  }
    
                  component = <div>
                      <h4>Adres wydarzenia<span>*</span></h4>
                      <p className="input-desc">Nazwa</p>
                      <input type="text" value={ val.metadata.name } onChange={ (e) => { val.metadata.name = e.target.value; this.updateItem(i, "metadata", val.metadata) } }/>
                      <div className="flex">
                        <div>
                          <p className="input-desc">Ulica</p>
                          <input type="text" value={ val.metadata.street } onChange={ (e) => { val.metadata.street = e.target.value; this.updateItem(i, "metadata", val.metadata) } }/>
                        </div>
                        <div>
                          <p className="input-desc">Numer budynku</p>
                          <input type="text" value={ val.metadata.buildingNumber } onChange={ (e) => { val.metadata.buildingNumber = e.target.value; this.updateItem(i, "metadata", val.metadata) } }/>
                        </div>
                        <div>
                          <p className="input-desc">Numer lokalu</p>
                          <input type="text" value={ val.metadata.apartmentNumber } onChange={ (e) => { val.metadata.apartmentNumber = e.target.value; this.updateItem(i, "metadata", val.metadata) } }/>
                        </div>
                      </div>
                      <div className="flex">
                        <div>
                          <p className="input-desc">Miasto</p>
                          <input type="text" value={ val.metadata.city } onChange={ (e) => { val.metadata.city = e.target.value; this.updateItem(i, "metadata", val.metadata) } }/>
                        </div>
                        <div>
                          <p className="input-desc">Kod pocztowy</p>
                          <input type="text" value={ val.metadata.postalCode } onChange={ (e) => { val.metadata.postalCode = e.target.value; this.updateItem(i, "metadata", val.metadata) } }/>
                        </div>
                      </div>
                      <div className="flex">
                        <div>
                          <p className="input-desc">Szerokość geograficzna</p>
                          <input type="text" value={ val.metadata.latitude || 0 } onChange={ (e) => { val.metadata.latitude = e.target.value; this.updateItem(i, "metadata", val.metadata) } }/>
                        </div>
                        <div>
                          <p className="input-desc">Długość geograficzna</p>
                          <input type="text" value={ val.metadata.longitude || 0 } onChange={ (e) => { val.metadata.longitude = e.target.value; this.updateItem(i, "metadata", val.metadata) } }/>
                        </div>
                      </div>
                    </div>
                } else if (item === "icon-text") {
                  let val = value
    
                  if (!val.file) {
                    val.file = {uuid: ""}
                  }
    
                  if(!val.columns) {
                    val.columns = [{custom: ""}]
                  }
    
                  component = <div>
                      <h4>Content</h4>
                      <p className="input-desc">Title</p>
                      <input type="text" value={ val.custom } onChange={ (e) => { this.updateItem(i, "custom", e.target.value) } }/>
                      <p className="input-desc">Description</p>
                      <input type="text" value={ val.columns[0].custom } onChange={ (e) => { val.columns[0].custom = e.target.value; this.updateItem(i, "columns", val.columns) } }/>
                      <h4>Image</h4>
                      <SingleImageUpload ident={ "icon" } API={ this.props.API } image={ val.file.uuid } onChange={ (img) => {
                        val.file.uuid = img
                        this.updateItem(i, "file", val.file)
                        } }/>
                    </div>
                } else if (item === "hero_image") {
                  let val = value
    
                  if (!val.file) {
                    val.file = {uuid: ""}
                  }
    
                  if(!val.metadata) {
                    val.metadata = {
                      imgDesc: ""
                    }
                  }
    
                  component = <div>
                      <h4>Images<span>*</span></h4>
                      <p className="input-desc">Image description</p>
                      <input type="text" value={ val.metadata.imgDesc } onChange={ (e) => { val.metadata.imgDesc = e.target.value; this.updateItem(i, "metadata", val.metadata) } }/>
                      <h4>Main image<span>*</span></h4>
                      <SingleImageUpload key={val.file.uuid} ident={ "thumbnail" } API={ this.props.API } image={ val.file } onChange={ (img) => {
                        val.file = img
                        this.updateItem(i, "file", val.file)
                        } }/>
                    </div>
                } else if (item === "thumbnail") {
                  let val = value
                  // console.log(val)
    
                  if (!val.file) {
                    val.file = {uuid: ""}
                  }
    
                  component = <div>
                      <h4>Thumbnail<span>*</span></h4>
                      <SingleImageUpload key={val.file.uuid} ident={ "thumbnail" } API={ this.props.API } image={ val.file } onChange={ (img) => {
                        val.file = img
                        this.updateItem(i, "file", val.file)
                        } }/>
                    </div>
                } else if (item === "cover_image_mobile" || item === "cover_image_desktop") {
                  let val = value
    
                  if (!val.file) {
                    val.file = {uuid: ""}
                  }
    
                  component = <div>
                      <h4>Background image { item === "cover_image_mobile" ? "mobile" : "desktop" }<span>*</span></h4>
                      <SingleImageUpload key={val.file.uuid} ident={ "thumbnail" } API={ this.props.API } image={ val.file } onChange={ (img) => {
                        val.file = img
                        this.updateItem(i, "file", val.file)
                        } }/>
                    </div>
                } else if (item === "image") {
                  let val = value
                  if (!val.custom) {
                    val.custom = {}
                  }
    
                  component = <div>
                      <h4>Image</h4>
                      <SingleImageUpload ident={ "image" } API={ this.props.API } image={ val.custom.image } onChange={ (img) => { val.custom.image = img; this.updateItem(i, "custom", val.custom) } }/>
                      <p className="input-desc">Alternative text</p>
                      <input type="text" value={ val.custom.altImage } onChange={ (e) => { val.custom.altImage = e.target.value; this.updateItem(i, "custom", val.custom) } }/>
                    </div>
                } else if (item === "category") {
                    let val = value
                    if (!val.custom) {
                      val.custom = ""
                    }
                    component = <div>
                        <h4>Category</h4>
                        <p className="input-desc">Will be notification category</p>
                        <input type="text" value={ val.custom } onChange={ (e) => {val.custom = e.target.value; this.updateItem(i, "custom", val.custom) } }/>
                      </div>
                  } 
                  else if (item === "url") {
                    let val = value
                    if (!val.custom) {
                      val.custom = ""
                    }
                    component = <div>
                        <h4>URL address</h4>
                        <p className="input-desc">Will be external URL if consists http/https on the beginning</p>
                        <input type="text" value={ val.custom } onChange={ (e) => {val.custom = e.target.value; this.updateItem(i, "custom", val.custom) } }/>
                      </div>
                  }  else if (item === "terms_pdf") {
                    let val = value
      
                    if (!val.file) {
                      val.file = {uuid: ""}
                    }
      
                    if(!val.metadata) {
                      val.metadata = {
                        name: "",
                        desc: ""
                      }
                    }
      
                    component = <div>
                        <h4>File</h4>
                        <p>File type: pdf</p>
                        <AddTerm restricted={false} API={ this.props.API } file={ val.metadata.name } onChange={ (file, fileName) => {
                          val.file = file
                          val.metadata.name = fileName
                          this.updateItem(i, "file", val.file)
                          } }/>
                        <p className="input-desc">View name</p>
                        <input type="text" value={ val.metadata.desc } onChange={ (e) => { val.metadata.desc = e.target.value; this.updateItem(i, "metadata", val.metadata) } }/>
                      </div>
                  }

                return (
                    <li key={ i } style={{ border: "none", padding: 0, marginTop: "16px" }} data-moduletype={item} data-moduleindex={i}>
                        { component }
                    </li>
                )
            }) }
        </ul>
      </div>
    )
  }
}
